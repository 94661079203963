/* eslint-disable */
import TranslationService from '@/services/TranslationService'
import { mapActions, mapGetters } from 'vuex'

export const translationMixin = {
  methods: {
    ...mapActions({
      setInstallmentsSeason: 'user/setInstallmentsSeason',
      setUSADuesSeason: 'user/setUSADuesSeason',
      setIOCDuesSeason: 'user/setIOCDuesSeason'
    }),
    $_capitalizeFirstLetters(val, toLowerCase) {
      // result like: `Monday`, `MemberType`
      if (toLowerCase) val = val.toLowerCase()
      val = val
        .trim()
        .split(' ')

      return val.map(v => v.charAt(0).toUpperCase() + v.slice(1)).join('')
    },

    /**
     * Returns a translated and formatted date string to use to display text
     * @param {string} givenDate result from 'getComponentTranslation()'
     * @param {object} optionsOverride Override if user needs a specific date format. Otherwise, default is used
     *   - See the MDN on how to structure the `optionsOverride` properly:
     *   - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     * @return {string} Returns either the `givenDate` or the translated & formatted date
     */
    returnFormattedDate(givenDate, optionsOverride) {
      if (!givenDate || (optionsOverride && typeof optionsOverride !== 'object')) return ''
      let options = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' }
      // Default format is English:`Saturday, Aug 14, 2021` OR Spanish:`sábado, 14 de ago de 2021`
      if (optionsOverride) options = optionsOverride

      try {
        return new Date(givenDate).toLocaleString(this.prefCulture, options)
      } catch (e) {
        console.error('Error in translationMixin, returnFormattedDate(), ', e)
        return givenDate
      } finally { }
    },
    /**
     * Strips readable text, that comes back from SiteCore and updates the `translations.common` object
     *  - `tcCampPresident$0f29afba...`  becomes  `0f29afba...`
     *
     * @param {object} incomingTranslations result from 'getComponentTranslation()'
     * @param {string} textOrGuid Allows user to keep readable text AND GUID (`guid` only (default), 'text' is both )
     * @return {boolean} Whether or not the translation object was updated successfully
     */
    async stripReadableText(incomingTranslations, textOrGuid) {
      if (!incomingTranslations) return false
      const newTranslationsGuid = {}
      const newTranslationsText = {}

      for (const [key, value] of Object.entries(incomingTranslations)) {
        const formattedKey = key.split('.')[1]
        newTranslationsGuid[formattedKey] = {}
        newTranslationsText[formattedKey] = {}
        const tempKeys = Object.keys(incomingTranslations[key])
        tempKeys.forEach(value => {
          const splitValue = value.split('$')
          newTranslationsText[formattedKey][splitValue[0]] = incomingTranslations[key][value] // text
          newTranslationsGuid[formattedKey][splitValue[1]] = incomingTranslations[key][value] // guid
        })
      }
      if (textOrGuid && textOrGuid === 'text') this.$set(this.translations, 'commonText', newTranslationsText)
      return this.$set(this.translations, 'common', newTranslationsGuid)
    },
    /**
     * @param {object[]} options Array of Objects containing the SiteCore node name ('node') and Vuex Store Name ('store')
     *   An optional key/value pair is the `disabledPlaceholder`, which will be a disabled item at the top of the dropdown
     *   An optional key/value pair is the `overrideItemKey`, which will allow flexibility when getting from the `item` object
     * @param {string} [givenTranslationLocation='translations'] typical translation is the default, 'translations'
     *   If needed, you can override the default (for example, when translations are in the props, `i18n`)

     * @return {boolean} Returning 'true' means a translation was successful.
     * @example
     *   translatedOptionsSupport([{ node: 'support-level', store: 'optionsSupport' }, ...], 'i18n')
     *   translatedOptionsSupport([{ node: 'address-types', store: 'optionsAddressTypes', disabledPlaceholder: 'Select an Address' }])
     */
    translateDropdownOptions(options, givenTranslationLocation) {
      if (!options || options.length < 1 || options.node === 'days') return false
      const translationLocation = givenTranslationLocation || 'translations'

      options.forEach(option => {
        if (!this[translationLocation].common || !this[translationLocation].common[option.node]) return false
        const optionsName = `${option.store}Translated`
        const optionsOverride = option.hasOwnProperty('overrideItemKey') ? option.overrideItemKey : false
        this[optionsName] = this[option.store].map(item => {
          if (!optionsOverride || typeof optionsOverride !== 'string') {
            item.text = this[translationLocation].common[option.node][item.value] || item.text
          } else {
            item.text = this[translationLocation].common[option.node][item.value[optionsOverride]] || item.text
          }
          return item
        })

      })
      options.forEach(option => { // If needed, add a disabled placeholder option, as the first option in the dropdown
        const optionsName = `${option.store}Translated`
        if (!option.disabledPlaceholder || !Array.isArray(this[optionsName])) return false
        this[optionsName].unshift({ value: null, text: option.disabledPlaceholder, disabled: true })
      })
      return true
    },

    /**
     * Uses `date.json` and iterates over the days of the week. While iterating, this method will match GUIDs from the
     *   `date.json` and find a match from SiteCore (or uses the English text as a fallback).
     *
     * Note: You MUST import the `date.json` file inside the main .vue file and set `this.days` to it
     *
     * @param {string} varNameForDaysOfWeek Name of variable within `data` which holds the Days of the Week
     * @require You must import the `date.json` file inside the main .vue file
     * @return {boolean} Returning an object means a translation was successful.
     * @example
     *   translateDropdownOptions('daysTranslated')
     */
    translateDropdownDaysOfWeek(varNameForDaysOfWeek) {
      if (!this.translations.common || !this.translations.common.days || !this.days) return false

      return (this[varNameForDaysOfWeek] = this.days.map(day => {
        return {
          text: this.translations.common.days[day.value.toLowerCase()] || day.text,
          value: day.value
        }
      }))
    },

    /**
     * Text-match day of week text. Uses the `date.json` to return GUIDs.
     *  - Both weekday GUIDs are returned, so you can retrieve the full text or abbreviated value from SiteCore
     *
     * Note: You MUST import the `date.json` file inside the main .vue file and set it to `this.daysJson`
     *
     * @param {string} weekdayText Day of week, either full text `Monday` or an abbreviation `mon`. Case insensitive
     * @return {object} Empty {} means no match found. Otherwise, an object containing weekday GUIDs is returned
     * @example
     *   getWeekdayGuid('Monday')
     *   getWeekdayGuid('tue')
     */
    getWeekdayGuid(weekdayText) {
      if (!weekdayText || !this.daysJson) return {}
      weekdayText = weekdayText.toLowerCase()

      const daysLower = this.daysJson.map(dl => {
        return { text: dl.text.toLowerCase(), abbr: dl.abbr.toLowerCase(), weekday: dl.weekday }
      })

      let weekdayIndex = daysLower.find(df => {
        return df.text === weekdayText || df.abbr === weekdayText.substr(0, 3)
      });

      if (!weekdayIndex) return {}

      return {
        guidFull: this.daysJson[weekdayIndex.weekday].value,
        guidAbbr: this.daysJson[weekdayIndex.weekday].guidAbbr,
      }
    },

    /**
     * Text-match month of year text. Uses the `date.json` to return GUIDs.
     *  - Both month GUIDs are returned, so you can retrieve the full text or abbreviated value from SiteCore
     *
     * Note: You MUST import the `date.json` file inside the main .vue file and set it to `this.monthsJson`
     *
     * @param {string} monthText Month of year, either full text `April` or an abbreviation `apr`. Case insensitive
     * @return {object} Empty {} means no match found. Otherwise, an object containing month GUIDs is returned
     * @example
     *   getMonthGuid('January')
     *   getMonthGuid('apr')
     */
    getMonthGuid(monthText) {
      if (!monthText || !this.monthsJson) return {}
      monthText = monthText.toLowerCase()

      const monthsLower = this.monthsJson.map(ml => {
        return { text: ml.text.toLowerCase(), abbr: ml.abbr.toLowerCase(), month: ml.value - 1 }
      })

      let monthIndex = monthsLower.find(mf => mf.text === monthText || mf.abbr === monthText.substr(0, 3)).month

      return {
        guidFull: this.monthsJson[monthIndex].guidFull,
        guidAbbr: this.monthsJson[monthIndex].guidAbbr
      }
    },

    // pass in any value and it will be returned with the value for use with `this.translations`
    // IE: 'tcMonday'  will be returned after passing in 'monday'. Used for day, month, member types...
    convertValForTranslation(val, toLowerCase) {
      return !val ? false : `tc${this.$_capitalizeFirstLetters(val, toLowerCase)}`
    },

    /**
     * Returns a translated value, based on a lookup GUID from SiteCore.
     * Value returned is based on the user's preferred language. Will fall back to English, if needed.
     *
     * @param {string} key A GUID, 36 characters in length, representing an Office Title: 20a7a450-d332-47f7-bff7-928af6bfded5
     * @param {string} translationField Translation field object to look in ('officer-titles', 'months', etc)
     * @param {string} originalValue English text, used as a fallback
     * @return {string} Translated text, either from a successful lookup or the default param. passed in
     */
    getTranslatedValue(key, translationField, originalValue) {
      if (!key || key.length !== 36) return originalValue
      if (this.translations && !this.translations.common) this.translations.common = {}
      if (!this.translations.common[translationField]) this.translations.common[translationField] = {}

      return this.translations.common[translationField][key.toLowerCase()] || originalValue
    },

    /**
     * Sets display text of occupation for member/prospect/candidate
     *  - Requires 'occupation-type' translation to be called & set to `this.translations.common`
     *
     * @return {boolean} Whether or not the 'set' was successful
     */
    async setTranslatedOccupationName() {
      if (!this.translations.common || !this.individualProfile) return false

      const occupationGuid = this.individualProfile.occupation_key || ''
      this.individualProfile.occupation = this.translations.common['occupation-type'].hasOwnProperty(occupationGuid) ?
        this.translations.common['occupation-type'][occupationGuid] :
        this.individualProfile.occupation
      return true
    },

    /**
     * Updates 'officer' field within the GideonMemberDetails and/or AuxiliaryMemberDetails
     *
     * @param {array} memberType Array of Member Type, IE: ['gideonMemberDetails', 'auxiliaryMemberDetails']
     * @return {boolean} Whether or not the translation and update was successful
     */
    async updateTranslatedOfficeTitles(memberType) {
      if (!memberType || !Array.isArray(memberType) || memberType.length < 1) return false

      try {
        memberType.forEach(type => {
          const updatedMembers = this[type].map(member => {
            member.office = this.getTranslatedValue(member.office_key, 'officer-titles', member.office)
            return member
          })
          this.setMemberType(updatedMembers, type.replace('MemberDetails', ''))
        })
        return true
      } catch (e) {
        console.error('Error in translationMixin.js, method name: updateTranslatedOfficeTitles(), ', e)
        return false
      }
    },

    /**
     * Updates Cabinet Title and Cabinet positions, used during multiple Add Announcement views.
     *  - Called from: AddStateAnnouncement & AddRegionalCommitteeAnnouncement
     * Uses string matching as there was no reliable GUID matching method (multiple queries used)
     * Requires a `localeAndMemberTypes` array of strings. This is populated based off the computed properties used in
     *   that specific view. IE, ['campAllLeaders', 'campAuxiliaryLeaders', 'campGideonLeaders', ...]
     * Requires a 'cabinetTypes'  object which matches the `localeAndMemberTypes` and has the translation lookup value,
     *   IE, cabinetTypes: { 'Gideon Areas': 'tcGideonAreas', 'Gideon Members': 'tcGideonMembers', ... }
     *
     * @return {boolean} Whether or not the translation and update was successful
     */
    async translateOfficerText() {
      if (!this.translations || !this.campGideonLeaders || !this.campAuxiliaryLeaders) return false
      this.localeAndMemberTypes.forEach(leader => {  // Cabinet Title, IE: `Gideon Camp Cabinet`, `Auxiliary Camp Cabinet`
        this[leader].forEach((leaderType, idx) => {
          // Uses string matching since there are no reliable guids returned from multiple queries
          this[leader][idx].title = this.cabinetTypes.hasOwnProperty(leaderType.title) ?
            this.translations[this.cabinetTypes[leaderType.title]] :
            leaderType.title

          this[leader][idx].areas = this[leader][idx].areas.map(item => {  // Cabinet Position, IE: `Camp President`
            const itemGuid = item.value || ''
            let itemText = this.translations.common['officer-titles'].hasOwnProperty(itemGuid) ?
              this.translations.common['officer-titles'][itemGuid] :
              item.text
            itemText = item.text.toLowerCase() === 'whole cabinet' ? this.translations.tcWholeCabinet : itemText
            return { ...item, text: itemText }
          })
        })
      })
      return true
    },

    removeSpacesAndSpecialChars(givenString) {
      if (!givenString || givenString instanceof String || givenString.length < 1) return ''
      const searchVal = /[^a-z0-9]/gi  // alphanumerics only, remove all spaces and special chars
      return givenString.replace(searchVal, '')
    },

    _removeFreshdesk() {
      let fs = document.getElementById('freshworks-script')
      let fc = document.getElementById('freshworks-container')
      return { fs, fc }
    },

    _removeOldDomElements(givenElements) {
      try {
        if (givenElements.fs) givenElements.fs.remove()
        if (givenElements.fc) givenElements.fc.remove()
      } catch (e) {
        console.error('Error during _removeOldDomElements(), ', e)
      }
      return true
    },

    async _destroyFreshDeskWidget() {
      try {
        if (window.FreshworksWidget && typeof window.FreshworksWidget == 'function') {
          window.FreshworksWidget('destroy')  // I have tried a variety of 'close' and 'hide' with no success
        }
      } catch (e) {
        console.error('error', e)
      } finally { }
      return true
    },

    async _createFreshDeskWidget(lang) {
      window.fwSettings = {
        widget_id: 67000002525,
        locale: lang
      }
      if ('function' != typeof window.FreshworksWidget) {
        let n = () => {
          n.q.push(arguments)
        }
          ; (n.q = []), (window.FreshworksWidget = n)
      }
      let scrp = document.createElement('script')
      scrp.setAttribute('src', 'https://widget.freshworks.com/widgets/67000002525.js')
      document.head.appendChild(scrp)
      return true
    },

    _valid(val, user) {
      if (!val || !user) return ''
      let tempValue = user.hasOwnProperty(val) ? user[val] : ''
      if (tempValue && typeof tempValue === 'string') {
        return tempValue
      } else {
        return ''
      }
    },

    async createOrRecreateFreshDeskWidget(user, prefCulture, translations) {
      let freshDeskLanguage = prefCulture.split('-')[0]
      if (!freshDeskLanguage) freshDeskLanguage = 'en'
      if (freshDeskLanguage === 'ru') freshDeskLanguage = prefCulture

      const fdDomElements = this._removeFreshdesk()  // save off old DOM elements, to be deleted later
      await this._destroyFreshDeskWidget()  // destroy widget (if it exists)
      await this._createFreshDeskWidget(freshDeskLanguage)
        .then(() => {
          setTimeout(() => {
            const userName = this._valid('name', user)
            const userEmail = this._valid('userName', user)
            const userNumber = this._valid('acountid', user)
            window.FreshworksWidget('identify', 'ticketForm', {
              name: userName,
              email: userEmail,
              custom_fields: {
                cf_zone: userNumber  // '227487'
              }
            })

            const labels = {}
            labels[freshDeskLanguage] = {
              banner: translations.tcTheConnectionSupportCenter,
              launcher: translations.tcHelp,
              contact_form: {
                title: translations.tcSubmitARequest,
                submit: translations.tcSubmit,
                confirmation: translations.tcFreshDeskConfirmation
              }
            }
            window.FreshworksWidget('setLabels', labels)
            return true
          }, 500)
          this._removeOldDomElements(fdDomElements)
        })
    },

    /**
     * This does a text-based lookup of the 'common' or 'commonText' object within `translations` object
     *  - This is done often when trying to match various data fields (Occupation, Facility Type, Relationship, etc)
     *
     * @param {string} givenField Name of field to search for (IE, 'Pastor', 'Nursing Home', 'Staff')
     * @param {string} givenNode Name of node name, from SiteCore (IE, 'occupation-type', 'placement-facility-types')
     * @param {string} givenLookupLocation (Optional) If needed, specify the object name within 'translations' obj
     * @return {string} Text/Translated Text of whatever the lookup result is
     */
    textLookup(givenField, givenNode, givenLookupLocation = 'common') {
      if (!givenField || !givenNode || !this.translations || !this.translations.commonText) return givenField

      const lookupText = this.convertValForTranslation(givenField)  // `tcPastor` || `tcNursingHome`
      return this.translations[givenLookupLocation][givenNode].hasOwnProperty(lookupText) ?
        this.translations[givenLookupLocation][givenNode][lookupText] :
        givenField
    },

    async getTranslations() {
      if (Object.keys(this.translations).length === 0) {
        let response = await TranslationService.getModuleTranslations(this.$options.name, this.prefCulture)
        if (!!response.data) {
          this.translations = response.data
          if (typeof response.data.installments_season !== 'undefined' && response.data.installments_season !== null) {
            this.setInstallmentsSeason(response.data.installments_season)
          }
          if (typeof response.data.usa_dues_season !== 'undefined' && response.data.usa_dues_season !== null) {
            this.setUSADuesSeason(response.data.usa_dues_season)
          }
          if (typeof response.data.ioc_dues_season !== 'undefined' && response.data.ioc_dues_season !== null) {
            this.setIOCDuesSeason(response.data.ioc_dues_season)
          }
        } else this.translations = {}
      }
    },

    /**
     * Get translations for Component(s) from SiteCore
     *  - If you need to pass a language in, pass in an OBJECT as the FIRST parameter, like this: {lang: 'es-ES'}
     * @param {string} args - Expects 1 - infinity arguments, as strings ('footer', 'login', ...)
     * @return {object} An object containing translations. { 'footer': {translations...}, 'login': {translations...}, ... }
     * @example
     *    getComponentTranslations('footer', 'login', 'security-banner')
     *    getComponentTranslations({lang: 'es-ES'}, 'camp-select', 'security-banner')
     */
    async getComponentTranslations(...args) {
      let language = args[0].lang || this.prefCulture
      if (args && args[0] !== null && typeof args[0] === 'object') {
        args.shift() // language passed in via 1st argument, now remove it
      }
      // add 'component' suffix. This suffix is used to located the translation
      // stored in the MRP (aka sitecore)
      const results = await TranslationService.postComponentTranslations(args, language)
      const componentTranslations = args.map((x, i) => {
        return { [x]: results.data[x] }
      })
      const resultObj = Object.assign({}, ...componentTranslations)
      return resultObj
    },


    async getPseudoComponentTranslations(...args) {
      let language = args[0].lang || this.prefCulture
      if (args && args[0] !== null && typeof args[0] === 'object') {
        args.shift() // language passed in via 1st argument, now remove it
      }

      const promises = args.map(x => TranslationService.getViewTranslations(x, language))

      const results = await Promise.all(promises)
      const componentTranslations = args.map((x, i) => {
        return { [x]: results[i].data }
      })
      const resultObj = Object.assign({}, ...componentTranslations)
      return resultObj
    },

    async getViewTranslations(...args) {
      if (Object.keys(this.translations).length === 0) {
        try {
          if (args != null && args.length > 0) {
            args = args.map(item => `shared.${item}`)
          }
          args.unshift(this.$options.name) // add the current module name to the translations to pull from sitecore
          let response = args.map(x => TranslationService.getViewTranslations(x, this.prefCulture))
          const results = await Promise.all(response)
          let mergeTranslations = {}
          if (results.length > 0) {
            results.map(item => {
              mergeTranslations = { ...mergeTranslations, ...item.data } // spread operator combine object and make it each prop unique
            })
          }

          if (!!mergeTranslations) {
            this.translations = mergeTranslations
            if (
              typeof mergeTranslations.usa_dues_season !== 'undefined' &&
              mergeTranslations.usa_dues_season !== null
            ) {
              this.setUSADuesSeason(mergeTranslations.usa_dues_season)
            }
            if (
              typeof mergeTranslations.ioc_dues_season !== 'undefined' &&
              mergeTranslations.ioc_dues_season !== null
            ) {
              this.setIOCDuesSeason(mergeTranslations.ioc_dues_season)
            }
          } else this.translations = {}
        } catch (error) {
          console.error(`There was an error retrieving translations for view ${this.$options.name}: ${error}`)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userLogin: 'user/userLogin'
    })
  }
}
