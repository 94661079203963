/* eslint-disable */
import date_data from '@/json/date.json'

let months = date_data.months.map(dm => dm.abbrlow)
let guidTest = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
const vmd = ['4', '5', '6'];//3 card types Visa, MC, Discover
const creditCardBrands = ['Check', 'Visa', 'MasterCard', 'AmericanExpress', 'Maestro', 'Discover', 'AMEX', 'Seven', 'Eight']

export const numericMixin = {
  methods: {
    handleAmountInputClick(evt) {
      if (evt.target.value === '0' || evt.target.value === '0.0' || evt.target.value === '0.00') {
        evt.target.value = ''
      }
    },
    handleLeadingDecimalEntry(evt) {
      if ((evt.target.value === '' || evt.target.value === '0' || evt.target.value === '0.0' || evt.target.value === '0.00') && evt.key == '.') {
        evt.target.value = '.'
      }
    },
    cardBrands(idx) { return creditCardBrands[idx] },
    isGuid(guid) {
      return guidTest.test(guid)
    },
    onlyNumber(evt) {
      if (isNaN(evt.key) && evt.key != '.') {
        evt.preventDefault()
        return 0
      }
    },
    onlyWholeNumber(evt) {
      if (isNaN(evt.key)) {
        evt.preventDefault()
      }
    },
    maxAmtExceeded(amt) {
      let maxTest = new RegExp(/^(100000000(\.00?)?|(\d{1,8})(\.\d{1,2})?)$/)
      return !maxTest.test(amt);
    },
    firstOfCurrentMonth() {
      const nd = new Date()
      const td = ((nd.getMonth() + 1) < 10 ? '0' : '') + (nd.getMonth() + 1) + '-' + (nd.getDate() < 10 ? '0' : '') + nd.getDate() + '-' + nd.getFullYear()
      nd.setDate(1)
      return [((nd.getMonth() + 1) < 10 ? '0' : '') + (nd.getMonth() + 1) + '-01-' + nd.getFullYear(), td]
    },
    formattedDate(dt) {
      if (!dt) {
        dt = new Date()
      }
      try {
        return (
          ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1) + '/' + (dt.getDate() < 10 ? '0' : '') + dt.getDate() + '/' + dt.getFullYear()
        )
      } catch (e) {
        const io = dt.indexOf('/')
        if (io === 1 || io === 2) {
          const dtPieces = dt.split('/')
          return ((dt.getMonth() + 1) < 10 ? '0' : '') + dtPieces[0] + '/' + (dtPieces[1].length < 2 ? '0' : '') + dtPieces[1] + '/' + dtPieces[2]
        }
      }
    },
    hasCreditCardExpired(exMonth, exYear) {
      return new Date(exYear, parseInt(exMonth, 10) - 1, 1) < new Date()
    },
    isValidCreditCard(ccNum) {
      let ccIsValidVMD = vmd.some(substr => ccNum.startsWith(substr)) && ccNum.length === 16
      let ccIsValidAmex = ccNum.startsWith('3') && ccNum.length === 15
      return this.luhnCheck(ccNum) & (ccIsValidAmex || ccIsValidVMD)
    },
    isValidCVV(ccNum, cvv) {
      let cvvIsValidVMD = vmd.some(substr => ccNum.startsWith(substr)) && cvv.length === 3
      let cvvIsValidAmex = ccNum.startsWith('3') && cvv.length === 4
      return cvvIsValidVMD || cvvIsValidAmex
    },
    luhnCheck(num) {
      const arr = `${num}`
        .split('')
        .reverse()
        .map(x => Number.parseInt(x))
      const lastDigit = arr.shift()
      let sum = arr.reduce(
        (acc, val, i) => i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val), 0)
      sum += lastDigit
      return sum % 10 === 0
    },
    numericDate(ndt) {
      return this.formattedDate(ndt).replace(/\//g, '')
    },
    returnAsCurrency(nbr) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      return formatter.format(nbr)
    },
    formattedDateForCards(dt) {
      var qDate = !!dt ? new Date(dt) : null
      var obj = {
        month: !!qDate ? months[qDate.getMonth()] : '',
        day: !!qDate ? qDate.getDate() : '',
        year: !!qDate ? qDate.getFullYear() : ''
      }
      return obj
    },
    formatTimeString(timeString) {
      if (!timeString) return ''
      const [hour, minute] = timeString.split(':')
      if (!hour || !minute) return ''
      const ampm = +hour >= 12 ? 'PM' : 'AM'
      let hourOutputInt = +hour > 12 ? +hour - 12 : +hour
      hourOutputInt = hourOutputInt === 0 ? 12 : hourOutputInt
      const hourOutputString = `${hourOutputInt}`.length === 1 ? `0${hourOutputInt}` : `${hourOutputInt}`
      return `${hourOutputString}:${minute}${ampm}`
    },
    formatTimeToMilitary(timeString) {
      const isMidnight = timeString === '12:00AM'
      if (isMidnight) return '00:00'
      const isPM = timeString.includes('PM')
      const base = timeString.replace('AM', '').replace('PM', '')
      const [hour, minute] = base.split(':')
      const militaryHour = isPM && hour !== '12' ? +hour + 12 : +hour
      return `${militaryHour}:${minute}`
    }
  }
}

export default numericMixin
