import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from 'vuex-persistedstate'

import { home } from './store/home'
import { user } from './store/user'
import { menu } from './store/menu'
import { churchMinistry } from './store/churchMinistry'
import { membership } from './store/membership'
import { announcements } from './store/announcements'
import { search } from './store/search'
import { card } from './store/card'
import { testimony } from './store/testimony'
import { successStory } from './store/successStory'
import { eventCalendar } from './store/eventCalendar'
import { prayerCenter } from './store/prayerCenter'
import { dashboard } from './store/dashboard'
import { scriptureDistribution } from './store/scriptureDistribution'
import { report } from './store/report'
import { reportParameter } from './store/reportParameter'
import { stateConvention } from './store/stateConvention'
import { election } from './store/election'
import { auxiliary } from './store/auxiliary'
import { campModule } from './store/campModule'
import { remittanceModule } from './store/remittanceModule'
import { memberShipRenewalModule } from './store/memberShipRenewalModule'
import { donationModule } from './store/donationModule'
import { userModule } from './store/userModule'
import { accountModule } from './store/accountModule'
import { mrp } from './store/mrp'
import { activityModule } from './store/activityModule'
import { translation } from './store/translation'
import { officersModule } from './store/officersModule'
import { gideonStore } from './store/gideonStore'
import { prayerAndWitnessing } from './store/opportunities'
import { digitalCandidateMember } from './store/digitalCandidateMember'
import { candidateModule } from './store/candidateModule'
import { directory } from './store/directory'
import { secureReport } from './store/secureReport'
import { usaScriptureBlitz } from './store/usaScriptureBlitz'
import { breadCrumbsTranslation } from './store/breadCrumbsTranslationModule'
import { conversations } from './store/conversations'
import { campGoalPlanner } from './store/campGoalPlanner'
import { stateGoalPlanner } from './store/stateGoalPlanner'
Vue.use(Vuex)

export default createStore(Vuex.Store, {
  modules: {
    home,
    user,
    menu,
    churchMinistry,
    membership,
    announcements,
    search,
    card,
    testimony,
    successStory,
    eventCalendar,
    dashboard,
    scriptureDistribution,
    prayerCenter,
    report,
    reportParameter,
    stateConvention,
    election,
    auxiliary,
    campModule,
    remittanceModule,
    donationModule,
    userModule,
    accountModule,
    mrp,
    activityModule,
    translation,
    officersModule,
    gideonStore,
    digitalCandidateMember,
    prayerAndWitnessing,
    candidateModule,
    directory,
    secureReport,
    usaScriptureBlitz,
    breadCrumbsTranslation,
    conversations,
    campGoalPlanner,
    stateGoalPlanner,
    memberShipRenewalModule
  },
  plugins: [createPersistedState()]
})
