/* eslint-disable */
export const state = () => ({
  processTime: 0,
})


export const getters = {
  processTime: state => {
    return state.processTime
  },
}

export const SET_PENDING_REMITTANCE_TIME = 'SET_PENDING_REMITTANCE_TIME'

export const mutations = {
  [SET_PENDING_REMITTANCE_TIME](state) {
    //Set this so users cannot submit members for renewal back to back.
    const now = new Date()
    state.processTime = now
  },
}

export const actions = {
    setPendingRenewalTime({ commit }) {
        commit('SET_PENDING_REMITTANCE_TIME')
      },
}


export const memberShipRenewalModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
