/* eslint-disable */
import { API_CONFIG } from '@/config/api.config'
import axios from 'axios'

const DONATION_MODEL_TRANSACTIONS_DEFAULT_VALUES = [
  {
    amount: 0,
    category: 'tcGideonCard',
    text: '',
    field: 'don_gideoncard',
    campaignCode: 'WEB',
    fundCode: 'DONGC',
    sourceCode: 'CONGive-GC',
    childItem: 0,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcAuxScriptureFund',
    text: '',
    field: 'don_aux_scripture',
    campaignCode: 'WEB',
    fundCode: 'DONAUXS',
    sourceCode: 'CONGive-AUXS',
    childItem: 0,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcFaithFund',
    text: '',
    field: 'don_faith_fund',
    campaignCode: 'WEB',
    fundCode: 'DONFF',
    sourceCode: 'CONGive',
    childItem: 0,
    displayOnly: 1
  },
  {
    amount: 0,
    category: 'tcAnnualPastorsEvent',
    text: '',
    field: 'don_annual_pastors_event',
    campaignCode: 'WEB',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-PAB',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcAreaFaithFundMeeting',
    text: '',
    field: 'don_area_faith_fund_rally',
    campaignCode: 'WEB',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-FF',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcStateConventionCountdown',
    text: 'tcCountdown100',
    field: 'don_countdown_100',
    campaignCode: 'STCV',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-CD100',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcStateConventionAux',
    text: 'tcAuxHeartProgram',
    field: 'don_aux_heart_program',
    campaignCode: 'STCVA',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-AUXHP',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcIntlConventionFF',
    text: 'tcSummerFaithFundRally',
    field: 'don_intl_convention_ff',
    campaignCode: 'CNV',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-SumFFRally',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcIntlConventionAux',
    text: 'tcSupportingAuxLuncheon',
    field: 'don_intl_convention_aux',
    campaignCode: 'CNVA',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-SuppAuxLun',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcAuxEvent',
    text: '',
    field: 'don_aux_event',
    campaignCode: 'WEB',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-AUXE',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcOtherCampFaithFundGift',
    text: '',
    field: 'don_other_ff_gift',
    campaignCode: 'WEB',
    fundCode: 'DONFF',
    sourceCode: 'CONGive-FFRally',
    childItem: 1,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcCampScriptureFund',
    text: '',
    field: 'don_camp_scripture',
    campaignCode: 'WEB',
    fundCode: 'DONCSF',
    sourceCode: 'CONGive-CSF',
    childItem: 0,
    displayOnly: 0
  },
  {
    amount: 0,
    category: 'tcBarnabasFund',
    text: '',
    field: 'don_barnabas',
    campaignCode: 'WEB',
    fundCode: 'DONBF',
    sourceCode: 'CONGive-BF',
    childItem: 0,
    displayOnly: 0
  },
  //FIXME: Uncomment below code when addition of two new general funds are confirmed and ready  to move to staging
  //#START
  // // TODO: update fund code, campaign code and source code after confirmation from the backend
  // {
  //   amount: 0,
  //   category: 'tcCampGeneralFund',
  //   text: '',
  //   field: 'don_camp_general',
  //   campaignCode: 'WEB',
  //   fundCode: 'DONGEN',
  //   sourceCode: 'CONGive-GF',
  //   childItem: 0,
  //   displayOnly: 1
  // },
  // // TODO: update fund code, campaign code and source code after confirmation from the backend
  // {
  //   amount: 0,
  //   category: 'tcNonTaxDeductiblePurchase',
  //   text: 'tcMealsOrPurchasingItems',
  //   field: 'don_camp_general',
  //   campaignCode: 'WEB',
  //   fundCode: 'DONGEN',
  //   sourceCode: 'CONGive-NTD',
  //   childItem: 1,
  //   displayOnly: 0
  // },
  // // TODO: update fund code, campaign code and source code after confirmation from the backend
  // {
  //   amount: 0,
  //   category: 'tcCharitableDonation',
  //   text: '',
  //   field: 'don_camp_general',
  //   campaignCode: 'WEB',
  //   fundCode: 'DONGEN',
  //   sourceCode: 'CONGive-CD',
  //   childItem: 1,
  //   displayOnly: 0
  // }
  //#END
]

export const state = () => ({
  donationModel: {
    transactions: DONATION_MODEL_TRANSACTIONS_DEFAULT_VALUES
  },
  donationItemsFromEntry: []
})
export const getters = {
  donationModel: state => {
    return state.donationModel
  },
  donationsSummary: state => {
    return state.donationItemsFromEntry || []
  }
}
export const actions = {
  async setDonationItems({ commit }, items) {
    await commit('SET_DONATION_ITEMS', items)
    return true
  },
  async processDonationPayment({ dispatch }, payload) {
    try {
      const response = await apiCalls.processDonation(payload)
      if (response && response.status === 200) {
        if (response.data.success) {
          return true
        } else {
          return response.data
        }
      }
    } catch (error) {
      console.error(error)
      return error.data
    }
  },
  async resetDonationsModelWithDefaults({ commit }) {
    // Due to use of VuePersist - addition of new funds/donations at RESET_DONATION_MODEL_WITH_DEFAULTS is not reflected as it is still referencing the old state in sync with localstorage
    // Call this method to make sure that donation model data is always in sync before applying further logic
    await commit(RESET_DONATION_MODEL_WITH_DEFAULTS)
  }
}
export const SET_DONATION_ITEMS = 'SET_DONATION_ITEMS'
export const RESET_DONATION_MODEL_WITH_DEFAULTS = 'RESET_DONATION_MODEL_WITH_DEFAULTS'
export const mutations = {
  [SET_DONATION_ITEMS](state, data) {
    state.donationItemsFromEntry = data
  },
  [RESET_DONATION_MODEL_WITH_DEFAULTS](state) {
    // Due to use of VuePersist - addition of new funds/donations at RESET_DONATION_MODEL_WITH_DEFAULTS is not reflected as it is still referencing the old state in sync with localstorage
    // Call this method to make sure that donation model data is always in sync before applying further logic
    state.donationModel.transactions = DONATION_MODEL_TRANSACTIONS_DEFAULT_VALUES
  }
}
const apiCalls = {
  processDonation(payload) {
    const route = API_CONFIG.processDonationPayment()
    return axios.post(route, payload)
  }
}
export const donationModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
